import { graphql } from 'gatsby';
import React from 'react';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import SEO from '../components/seo';

const News = ({ data, location }) => {
  return (
    <>
      <SEO
        title="Reopening"
        description="The lockdown is behind us and we've restarted our classes!"
        keywords={[`dance`, `reopen`, `covid`, `lockdown`]}
        location={location}
        image={data.og.sharp.fixed}
      />
      <ScrollUpButton style={{ zIndex: '99' }} />
      <section>
        <div className="flex flex-col md:block text-justify">
          <h1>Reopening Update</h1>
          <p className="text-xs mt-0">29th August, 2021</p>
          <div className="max-w-xl m-auto mt-6 space-y-2">
            <p>Here's the latest:</p>
            <ul className="list-disc pl-8 space-y-2">
              <li>
                The Scala location has closed down, classes run by Sonia and
                Louise are now being held at Hillside Hall, which has had a
                facelift.
              </li>
              <li>
                Classes at Kilmersdon are now being run at The Orchard Hall.
              </li>
              <li>
                Paulton Rovers Club is running as usual except for Mondays
                8.00-9.00pm.
              </li>
              <li>
                We have had two successful social dances on 31st July and 21st
                August. It was fabulous to see people dancing and socialising
                again!
              </li>
              <li>
                A new Beginners class at Paulton Rovers in January, details to
                be announced.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  query {
    og: file(relativePath: { eq: "private-lessons.jpg" }) {
      sharp: childImageSharp {
        fixed(width: 1200) {
          width
          height
          src
        }
      }
    }
  }
`;

export default News;
